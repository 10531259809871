<template>
  <v-footer
    id="dashboard-core-footer"
  >
    <v-container
      class="ma-0"
    >
      <v-row
        align="center"
        no-gutters
      >
        <v-col
          cols="12"
          md="auto"
        >
          <div class="body-1 font-weight-light pt-6 pt-md-0 text-center">
            &copy; 2020, made with
            <v-icon size="20">
              mdi-heart
            </v-icon>
            by Extendas.
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
  export default {
    name: 'DashboardCoreFooter',
  }
</script>

<style lang="sass">
  #dashboard-core-footer
    a
      font-size: .825rem
      font-weight: 500
      text-decoration: none
      text-transform: uppercase
</style>
